import {$fetch} from "ofetch";

export interface Recurso {
    id: number,
    titulo: string,
    url: string,
    mimetype: string,
}

export const useRecursoStore = defineStore('recurso', {
    state() {
        const BASE_URL = useRuntimeConfig().public.BASE_URL;
        return {
            BASE_URL,
        }
    },

    actions: {
        async buscarRecursosPorOrigemId(origemId: number) {
            const url = `${this.BASE_URL}/recurso/origem/${origemId}`;
            return await $fetch<Recurso[]>(url, {
                method: 'GET'
            });
        },
    }
})